import React from "react"

// SEO
import SEO from "../components/SEO"

// Components
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"

// Data
import content from "../pages/static-pages/where-to-buy.yaml"

class Index extends React.Component {
  render() {
    const breadcrumbs = [
      {
        label: content.title,
        path: "/stockists/",
        active: true,
      },
    ]

    return (
      <Layout>
        <SEO
          title={content.title}
          description={content.seo_description}
          image={content.seo_image}
        />

        <Breadcrumbs items={breadcrumbs} absolute={true} />

        {/* Feature */}
        <div className="feature-banner uk-background-secondary">
          <div className="uk-container uk-container-expand-right">
            <div className="uk-grid uk-grid-large" uk-grid="">
              <div className="uk-width-1-1 uk-width-2-5@m">
                <div className="uk-padding uk-padding-remove-left uk-margin-large-top uk-margin-large-bottom">
                  <h1 className="uk-margin-small-top uk-text-global-family uk-h2 uk-text-bold">
                    {content.feature.title}
                  </h1>
                  <p className="uk-text-p-large uk-text-primary">
                    {content.feature.tagline}
                  </p>
                  <a className="uk-text-muted" href="https://reece.com/location-search">
                    Click to find your nearest store:
                  </a>
                  <div className="uk-flex uk-flex-wrap uk-flex-middle uk-margin-top">
                    {content.stores.map((store, i) => (
                      <a
                        href={store.link}
                        key={i}
                        target="_blank"
                        className="uk-margin-right uk-margin-small-bottom"
                      >
                        <img
                          src={store.image.url}
                          alt={store.image.title}
                          style={{ maxWidth: "120px", height: "auto" }}
                        />
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="uk-width-1-1 uk-width-3-5@m">
                <div className="uk-container-item-padding-remove-right stockist-hero">
                  <div
                    className="uk-cover-container uk-background-cover uk-height-1-1"
                    data-src={content.feature.image.url}
                    uk-img=""
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="uk-section uk-section-small">
          <div className="uk-container">
            <div className="uk-width-3-4 uk-width-2-3@s uk-width-1-2@m">
              <small className="uk-text-meta">{content.description} </small>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index
